import React from 'react';
import Layout from '../component/layout';
//import HomeImage from "../images/financial-dev-home-image.png";
import ServicesHomeComponentV2 from '../component/servicesHomeComponentV2';
import WebsLogoSwiper from '../component/websLogoSwiper';
import ItExpandTeamComponent from '../component/Services/IT/ItExpandTeamComponent';
import ItStaffComponent from '../component/Services/IT/ItStaffComponent';
import Icon from '../images/card-icon.png';
import CardsComponent from '../component/CardsComponent';
import TechStackComponent from '../component/Services/techStackComponent';
import CustomDevelopmentVideoComponent from '../component/Services/CustomDevelopment/customDevelopmentVideoComponent';
import BookCallServiceComponent from '../component/bookCallServiceComponent';
import AccordionComponent from '../component/accordionComponent';
import FooterComponent from '../component/footerComponent';
import { graphql } from 'gatsby';
import BorderCards from '../component/BorderCards';

const serviceFinancialDev = props => {
  const seo = {
    title: 'Financial Software Development Services | Motomtech',
    metaDesc:
      'Our financial software development services have helped businesses ranging from established banks undergoing a digital transformation to FinTech startups.',
  };
  const {
    data: {
      wpPage: {
        companyMissionSection: {
          companyMissionTitle,
          companyMissionDescription,
          companyMissionImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: HomeImage },
            },
          },
        },
        homePageReviewSection: {
          homePageReviewTitle,
          homePageReviewAgreedDescription,
          homePageReviewAgreedClient,
          homePageReviewAgreedPosition,
          homePageReviewBashDescription,
          homePageReviewBashClient,
          homePageReviewBashPosition,
        },

        comprehensiveOutsourcedSoftwere: {
          cosTitle,
          cosDescription,
          cosSubtitle,
          cosCardTitle1,
          cosCardTitle2,
          cosCardTitle3,
          cosCardTitle4,
          cosCardTitle5,
          cosCardDescription1,
          cosCardDescription2,
          cosCardDescription3,
          cosCardDescription4,
          cosCardDescription5,
          cosParagraph1,
          cosParagraph2,
        },
        digitalApplicationDevelopmentServices: {
          digitalApplicationDevelopmentFrontend,
          digitalApplicationDevelopmentBackend,
          digitalApplicationDevelopmentProgressive,
          digitalApplicationDevelopmentSaas,
          digitalApplicationDevelopmentECommerce,
          digitalApplicationDevelopmentCustom,
          digitalApplicationDevelopmentTitle,
          digitalApplicationDevelopmentDescription,
        },
        servicesItAugmentationSolutions: {
          customDevelopmentTitle,
          customDevelopmentDescription,
          customDevelopmentCardTitle1,
          customDevelopmentCardDescription1,
          customDevelopmentCardTitle2,
          customDevelopmentCardDescription2,
          customDevelopmentCardTitle3,
          customDevelopmentCardDescription3,
          customDevelopmentCardTitle4,
          customDevelopmentCardDescription4,
          customDevelopmentCardTitle5,
          customDevelopmentCardDescription5,
          customDevelopmentCardTitle6,
          customDevelopmentCardDescription6,
          customDevelopmentCardTitle7,
          customDevelopmentCardDescription7,
          customDevelopmentCardTitle8,
          customDevelopmentCardDescription8,
          customDevelopmentCardTitle9,
          customDevelopmentCardDescription9,
          customDevelopmentCardTitle10,
          customDevelopmentCardDescription10,
          customDevelopmentCardTitle11,
          customDevelopmentCardDescription11,
        },

        processSection: {
          processSubtitle,
          processDescription,
          processAnalysisTitle,
          processAnalysisDescription,
          processProductTitle,
          processProductDescription,
          processDesigningTitle,
          processDesigningDescription,
        },
      },
    },
  } = props;
  const arr1 = [
    {
      title: (
        <p className='it-staff__wrapper-content-text-title'>
          Robust
          <span> Security:</span>
        </p>
      ),
      description: digitalApplicationDevelopmentProgressive,
      number: '1',
    },

    {
      title: (
        <p className='it-staff__wrapper-content-text-title'>
          Regulatory
          <span> Compliance:</span>
        </p>
      ),
      description: digitalApplicationDevelopmentSaas,

      number: '2',
    },

    {
      title: (
        <p className='it-staff__wrapper-content-text-title'>
          Quality
          <span> Assurance:</span>
        </p>
      ),
      description: digitalApplicationDevelopmentECommerce,

      number: '3',
    },
  ];
  const arr2 = [
    {
      title: (
        <p className='it-staff__wrapper-content-text-title'>
          Expert FinTech
          <span> Engineers:</span>
        </p>
      ),
      description: digitalApplicationDevelopmentCustom,
      number: '4',
    },
    {
      title: (
        <p className='it-staff__wrapper-content-text-title'>
          Long-Term
          <span> Partnership:</span>
        </p>
      ),
      description: digitalApplicationDevelopmentTitle,
      number: '5',
    },
    {
      title: (
        <p className='it-staff__wrapper-content-text-title'>
          Agile
          <span> Approach:</span>
        </p>
      ),
      description: digitalApplicationDevelopmentDescription,
      number: '6',
    },
  ];

  const cards = [
    {
      icon: Icon,
      title: customDevelopmentCardTitle1,
      description: customDevelopmentCardDescription1,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle2,
      description: customDevelopmentCardDescription2,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle3,
      description: customDevelopmentCardDescription3,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle4,
      description: customDevelopmentCardDescription4,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle5,
      description: customDevelopmentCardDescription5,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle6,
      description: customDevelopmentCardDescription6,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle7,
      description: customDevelopmentCardDescription7,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle8,
      description: customDevelopmentCardDescription8,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle9,
      description: customDevelopmentCardDescription9,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle10,
      description: customDevelopmentCardDescription10,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle11,
      description: customDevelopmentCardDescription11,
    },
  ];

  const questions1 = [
    {
      title: processAnalysisTitle,
      description: (
        <p
          className='accordion-section__card-desc'
          dangerouslySetInnerHTML={{
            __html: processAnalysisDescription.replace(/<br\s?\/?>/g, ''),
          }}
        />
      ),
      eventKey: 1,
    },
    {
      title: processProductTitle,
      description: (
        <p
          className='accordion-section__card-desc'
          dangerouslySetInnerHTML={{
            __html: processDesigningDescription.replace(/<br\s?\/?>/g, ''),
          }}
        />
      ),
      eventKey: 2,
    },
  ];

  const questions2 = [
    {
      title: processDesigningTitle,
      description: (
        <p
          className='accordion-section__card-desc'
          dangerouslySetInnerHTML={{
            __html: processProductDescription.replace(/<br\s?\/?>/g, ''),
          }}
        />
      ),
      eventKey: 3,
    },
  ];
  return (
    <Layout seo={seo} title={seo.title}>
      <ServicesHomeComponentV2
        homeTitle={companyMissionTitle}
        homeDescription={companyMissionDescription}
        homeImage={HomeImage}
        homeButton={'Request our services'}
      />
      <WebsLogoSwiper />
      <ItExpandTeamComponent
        title={homePageReviewTitle}
        description1={homePageReviewAgreedDescription}
        description2={homePageReviewAgreedClient}
        description3={homePageReviewAgreedPosition}
        description4={homePageReviewBashDescription}
        description5={homePageReviewBashClient}
        description6={homePageReviewBashPosition}
      />
      <BorderCards
        borderCardsTitle={cosTitle}
        borderCardsText1={cosDescription}
        borderCardsText2={cosSubtitle}
        borderCardsText3={cosCardTitle1}
        borderCardsText4={cosCardDescription1}
        borderCardsText5={cosCardTitle2}
        borderCardsText6={cosCardDescription2}
        borderCardsText7={cosCardTitle3}
        borderCardsText8={cosCardDescription3}
        borderCardsText9={cosCardTitle4}
        borderCardsText10={cosCardDescription4}
        borderCardsText11={cosCardTitle5}
        borderCardsText12={cosCardDescription5}
        borderCardsText13={cosParagraph1}
        borderCardsText14={cosParagraph2}
      />
      <ItStaffComponent
        title={digitalApplicationDevelopmentFrontend}
        description={digitalApplicationDevelopmentBackend}
        arr1={arr1}
        arr2={arr2}
      />
      <CardsComponent
        cards={cards}
        title={customDevelopmentTitle}
        description={customDevelopmentDescription}
      />
      <div style={{ position: 'relative', zIndex: '3' }}>
        <TechStackComponent />
      </div>
      <CustomDevelopmentVideoComponent />
      <AccordionComponent
        title={processSubtitle}
        subtitle={processDescription}
        arr1={questions1}
        arr2={questions2}
      />
      <div
        style={{
          background:
            'linear-gradient(108.34deg, #09062D -2.63%, #36498C 100%)',
        }}
      >
        <BookCallServiceComponent
          bookCallServiceTitle={'Jumpstart your startup with Motomtech'}
          bookCallServiceDescription={'Stop wasting time.'}
          isFirstService={true}
          isSecondService={false}
        />
      </div>
      <FooterComponent />
    </Layout>
  );
};

export default serviceFinancialDev;

export const query = graphql`
  {
    wpPage(slug: { eq: "service-financial-development" }) {
      seo {
        title
        metaDesc
      }
      companyMissionSection {
        companyMissionTitle
        companyMissionDescription
        companyMissionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      homePageReviewSection {
        homePageReviewTitle
        homePageReviewAgreedDescription
        homePageReviewAgreedClient
        homePageReviewAgreedPosition
        homePageReviewBashDescription
        homePageReviewBashClient
        homePageReviewBashPosition
      }
      comprehensiveOutsourcedSoftwere {
        cosTitle
        cosDescription
        cosSubtitle
        cosCardTitle1
        cosCardTitle2
        cosCardTitle3
        cosCardTitle4
        cosCardTitle5
        cosCardDescription1
        cosCardDescription2
        cosCardDescription3
        cosCardDescription4
        cosCardDescription5
        cosParagraph1
        cosParagraph2
      }
      digitalApplicationDevelopmentServices {
        digitalApplicationDevelopmentFrontend
        digitalApplicationDevelopmentBackend
        digitalApplicationDevelopmentProgressive
        digitalApplicationDevelopmentSaas
        digitalApplicationDevelopmentECommerce
        digitalApplicationDevelopmentCustom
        digitalApplicationDevelopmentTitle
        digitalApplicationDevelopmentDescription
      }
      servicesItAugmentationSolutions {
        customDevelopmentTitle
        customDevelopmentDescription
        customDevelopmentCardTitle1
        customDevelopmentCardTitle2
        customDevelopmentCardTitle3
        customDevelopmentCardTitle4
        customDevelopmentCardTitle5
        customDevelopmentCardTitle6
        customDevelopmentCardTitle7
        customDevelopmentCardTitle8
        customDevelopmentCardTitle9
        customDevelopmentCardTitle10
        customDevelopmentCardTitle11
        customDevelopmentCardDescription1
        customDevelopmentCardDescription2
        customDevelopmentCardDescription3
        customDevelopmentCardDescription4
        customDevelopmentCardDescription5
        customDevelopmentCardDescription6
        customDevelopmentCardDescription7
        customDevelopmentCardDescription8
        customDevelopmentCardDescription9
        customDevelopmentCardDescription10
        customDevelopmentCardDescription11
      }
      processSection {
        processSubtitle
        processDescription
        processAnalysisTitle
        processAnalysisDescription
        processProductTitle
        processProductDescription
        processDesigningTitle
        processDesigningDescription
      }
    }
  }
`;
